 <template>
  <section class="newLabel">
    <el-form
      @submit.native.prevent
      :model="newLabelForm"
      ref="newLabelForm"
      :rules="newLabelFormRule"
      label-width="120px"
    >
      <div class="conbox">
        <h3 @click="collapse = !collapse">
          基础信息
          <span class="fr cantrol">
            <i
              :class="collapse ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
            ></i>
          </span>
        </h3>
        <el-row v-show="collapse">
          <div style="margin-top: 10px">
            <el-col :span="12">
              <el-form-item prop="name" label="标签名称：">
                <el-input
                  v-model="newLabelForm.name"
                  placeholder="标签名最多不超过20个字"
                  style="width: 80%"
                  :maxlength="20"
                  @blur="checkisNameExist"
                  :onkeyup="
                    newLabelForm.name
                      ? (newLabelForm.name = newLabelForm.name.replace(
                          /\s+/g,
                          ''
                        ))
                      : ''
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="labelType" label="标签分类：">
                <el-select
                  placeholder="请选择"
                  v-model="newLabelForm.labelType"
                  style="width: 80%"
                >
                  <el-option
                    v-for="(i, indexKPI) in typesList"
                    :key="'sel' + indexKPI"
                    :value="i.value"
                    :label="i.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="generateModes" label="打标签方式：">
                <el-checkbox-group
                  v-model="newLabelForm.generateModes"
                  @change="changeApplyTarget"
                >
                  <el-checkbox
                    border
                    size="small"
                    label="RULE_BASED"
                    style="margin-right: 10px"
                    >自动标签</el-checkbox
                  >
                  <el-checkbox
                    border
                    size="small"
                    label="MANUAL"
                    style="margin-left: 0"
                    >手动标签</el-checkbox
                  >
                  <el-tooltip
                    content="自动标签指系统按照生成逻辑在应用对象上自动生成，手动标签指由用户手动添加"
                    placement="bottom"
                    effect="dark"
                  >
                    <i
                      class="iconfont icon icon-wenhao-xiangsu"
                      style="
                        font-size: 20px;
                        margin-left: 8px;
                        color: #999;
                        font-weight: 400;
                      "
                    ></i>
                  </el-tooltip>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                newLabelForm.labelType !== 'RESUME' &&
                newLabelForm.labelType !== 'CUSTOMER_INFLUENCE' &&
                newLabelForm.labelType !== 'CUSTOMER_COOPERATION'
              "
            >
              <el-form-item prop="categoryIds" label="类别：">
                <el-select
                  v-model="newLabelForm.categoryIds"
                  placeholder="请选择"
                  style="width: 80%"
                  clearable
                  filterable
                  collapse-tags
                  multiple
                >
                  <el-option
                    v-for="(item, index) in categoryIdsList"
                    :key="'a_' + index"
                    :value="item.id"
                    :label="item.name"
                  >
                  </el-option>
                </el-select>
                <span class="tips tabHref" @click="showTypes"
                  >管理标签类别</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="remark" label="备注：">
                <el-input
                  v-model="newLabelForm.remark"
                  type="textarea"
                  :maxlength="200"
                  placeholder="最多不超过200个字"
                  style="width: 80%"
                ></el-input>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </div>
      <div class="conbox">
        <h3 @click="collapse1 = !collapse1">
          应用逻辑
          <span class="fr cantrol">
            <i
              :class="collapse1 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
            ></i>
          </span>
        </h3>
        <el-row v-show="collapse1">
          <div style="margin-top: 10px">
            <el-col :span="12">
              <el-form-item prop="applyTarget" label="应用对象：">
                <el-select
                  v-model="newLabelForm.applyTarget"
                  placeholder="请选择"
                  style="width: 80%"
                  clearable
                  filterable
                  @change="changeApplyTarget"
                >
                  <el-option
                    v-for="(item, index) in applyTargetsList"
                    :key="'a_' + index"
                    :value="item.value"
                    :label="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </div>
      <div
        class="conbox"
        v-if="
          newLabelForm.generateModes[0] == 'RULE_BASED' ||
          newLabelForm.generateModes[1] == 'RULE_BASED'
        "
      >
        <h3 @click="collapse2 = !collapse2">
          生成逻辑
          <span class="fr cantrol">
            <i
              :class="collapse2 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
            ></i>
          </span>
        </h3>
        <el-row v-show="collapse2">
          <div style="margin-top: 10px">
            <el-col :span="24">
              <el-form-item class="" prop="rules" label="" label-width="30px">
                <div style="display: flex; align-items: center">
                  <i class="isRequired"></i>
                  <p class="tit">或</p>
                  <div>
                    <div
                      v-for="(i, index) in rules"
                      :key="index"
                      style="display: flex; margin-bottom: 5px"
                    >
                      <el-select
                        v-model="i.primaryFactor"
                        placeholder="请选择一级匹配对象"
                        clearable
                        filterable
                        @change="getPrimaryFactor($event, index)"
                        class="generateSelect"
                      >
                        <el-option
                          v-for="(item, index) in primaryFactorList"
                          :key="'a_' + index"
                          :value="item.id"
                          :label="item.name"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="i.secondaryFactor"
                        placeholder="请选择二级匹配对象"
                        clearable
                        filterable
                        class="generateSelect"
                      >
                        <el-option
                          v-for="(item, index) in i.secondaryFactorList"
                          :key="'a_' + index"
                          :value="item.factor"
                          :label="item.name"
                        >
                        </el-option>
                      </el-select>
                      <el-select v-model="i.operator" style="width: 100px">
                        <el-option value="=~" label="包含"> </el-option>
                        <el-option value="==" label="等于"> </el-option>
                      </el-select>
                      <div class="inputContent">
                        <el-tag
                          v-for="(x, xindex) in i.dataValues"
                          :key="xindex"
                          :title="x"
                          closable
                          type="info"
                          @close="delItem(index,xindex)"
                        >
                          {{ x }}
                        </el-tag>
                        <el-input
                          v-model="i.dataValue"
                          style="width: 350px; border: 0"
                          placeholder="请输入自定义数据集，多个自定义项可用空格隔开"
                          @input="getInpData(index)"
                        >
                        </el-input>
                      </div>

                      <span
                        v-if="index == 0"
                        class="tabHref"
                        @click="addNewRules"
                        style="margin-left: 10px; width: 80px"
                        >添加规则</span
                      >
                      <span
                        v-if="copy && index == 0"
                        class="tabHref"
                        @click="handleInpData('clean')"
                        style="margin-left: 10px; width: 80px"
                        >一键清空</span
                      >
                      <span
                        v-if="copy && index == 0"
                        class="tabHref"
                        @click="handleInpData('copy', i.dataValue)"
                        style="margin-left: 10px; width: 80px"
                        >一键复制</span
                      >

                      <span
                        v-if="index > 0"
                        class="tabHref"
                        @click="delRules(index)"
                        style="margin-left: 10px; font-size: 16px"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 12px; margin-left: 5px">
                  <span class="tips" @click="addNewAndFactor">
                    <i class="el-icon-plus"></i>添加且
                  </span>
                  <div
                    v-if="andFactor.length > 0"
                    style="display: flex; align-items: center"
                  >
                    <span class="tit">且</span>
                    <div>
                      <div
                        v-for="(y, index) in andFactor"
                        :key="index"
                        style="margin-bottom: 5px"
                      >
                        <el-select
                          v-model="y.andFactor"
                          placeholder="请选择"
                          clearable
                          filterable
                        >
                          <el-option
                            v-for="(item, index) in andFactorList"
                            :key="'a_' + index"
                            :value="item.factor"
                            :label="item.name"
                          >
                          </el-option>
                        </el-select>
                        <span
                          class="tabHref"
                          @click="delAndFactor(index)"
                          style="margin-left: 0px; font-size: 16px"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </div>
      <div class="conbox">
        <h3 @click="collapse3 = !collapse3">
          显示逻辑
          <span class="fr cantrol">
            <i
              :class="collapse3 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
            ></i>
          </span>
        </h3>
        <el-row v-show="collapse3">
          <div style="margin-top: 10px">
            <el-col :span="12">
              <el-form-item prop="sortOrder" label="排序：">
                <el-input
                  type="number"
                  v-model="newLabelForm.sortOrder"
                  style="width: 80%"
                  @blur="checkisOrderExist"
                  @keyup.native="number"
                ></el-input>
                <el-tooltip placement="top" effect="dark">
                  <template #content>
                    建议技能标签类下的各标签排序设置在1-2000之间<br />
                    履历标签类下的各标签排序设置在2001-3000之间<br />
                    前端页面将按照排序值从小到大排序</template
                  >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="font-size: 20px; margin-left: 8px"
                  ></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="displayAreas" label="显示在：">
                <el-select
                  v-if="newLabelForm.applyTarget == 'CANDIDATE'"
                  v-model="newLabelForm.displayAreas"
                  placeholder="请选择"
                  style="width: 80%"
                  clearable
                  filterable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(item, index) in displayAreasList"
                    :key="'a_' + index"
                    :value="item.value"
                    :label="item.name"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="newLabelForm.applyTarget == 'CUSTOMER'"
                  v-model="newLabelForm.displayAreas"
                  placeholder="请选择"
                  style="width: 80%"
                  clearable
                  filterable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(item, index) in displayAreasList1"
                    :key="'a_' + index"
                    :value="item.value"
                    :label="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-row style="clear: both">
              <el-col :span="12">
                <el-form-item prop="bordered" label="标签颜色：">
                  <el-select
                    v-model="newLabelForm.bordered"
                    placeholder="请选择"
                    style="width: 80%; margin-right: 0"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in borderedList"
                      :key="'a_' + index"
                      :value="item.value"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-tooltip
                    content="无底色：指底色为白色，色值只作用于标签边框和文字"
                    placement="top"
                    effect="dark"
                  >
                    <i
                      class="iconfont icon icon-wenhao-xiangsu"
                      style="font-size: 20px; margin-left: 8px"
                    ></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="color" label="色值：">
                  <el-popover
                    placement="right"
                    :width="165"
                    trigger="click"
                    popper-class="isColorContent"
                    :visible="visible"
                    ref="popover"
                  >
                    <template #reference>
                      <span
                        class="colorBtn"
                        :style="{ background: newLabelForm.color }"
                        @click="visible = true"
                      ></span>
                    </template>
                    <div class="colorBtnContent">
                      <span
                        class="colorBtn"
                        v-for="(i, index) in colorList"
                        :key="index"
                        :style="{ background: i }"
                        @click="getColor(i), (visible = false)"
                      ></span>
                    </div>
                  </el-popover>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </div>
    </el-form>
  </section>
</template>

<script>
import {
  getLabelFactorCandidateOr, //获取人才规则因子-或
  getLabelFactorCandidateAnd, //获取人才规则因子-且
  addLabel, //创建标签
  isNameExist, //检查名称是否已存在
  isOrderExist, //检查顺序是否已存在
  labelEditData, //获取标签编辑数据
  editLabel, //编辑标签
} from "../../api/api";
export default {
  props: ["categoryIdsList", "editData", "copy"],
  data() {
    return {
      newLabelForm: {
        name: null,
        labelType: "SKILL",
        generateModes: ["RULE_BASED"],
        categoryIds: [],
        remark: null,
        applyTarget: "CANDIDATE",
        sortOrder: null,
        displayAreas: [],
        bordered: true,
        color: "#56baed",
        orRules: [],
        andRules: [],
        version: 0,
      },
      newLabelFormRule: {
        name: [
          { required: true, trigger: "blur", message: "标签名称不能为空" },
        ],
        labelType: [
          { required: true, trigger: "change", message: "标签分类不能为空" },
        ],
        generateModes: [
          { required: true, trigger: "change", message: "打标签方式不能为空" },
        ],
        categoryIds: [
          { required: true, trigger: "change", message: "类别不能为空" },
        ],
        applyTarget: [
          { required: true, trigger: "change", message: "应用对象不能为空" },
        ],
        sortOrder: [
          { required: true, trigger: "blur", message: "排序不能为空" },
        ],
        displayAreas: [
          { required: true, trigger: "change", message: "显示不能为空" },
        ],
        bordered: [
          { required: true, trigger: "change", message: "标签颜色不能为空" },
        ],
        color: [{ required: true, trigger: "change", message: "色值不能为空" }],
        // rules: [{ required: true }],
      },
      collapse: true,
      collapse1: true,
      collapse2: true,
      collapse3: true,
      typesList: [
        {
          value: "SKILL",
          name: "人才技能标签",
        },
        {
          value: "RESUME",
          name: "人才履历标签",
        },
        {
          value: "CUSTOMER_INFLUENCE",
          name: "客户影响力标签",
        },
        {
          value: "CUSTOMER_COOPERATION",
          name: "客户合作类标签",
        },
      ],
      applyTargetsList: [
        {
          value: "CANDIDATE",
          name: "人才",
        },
        {
          value: "CUSTOMER",
          name: "客户",
        },
      ],
      displayAreasList: [
        {
          value: "CANDIDATE_PAGING",
          name: "人才列表",
        },
        {
          value: "CANDIDATE_PROFILE",
          name: "人才详情-用户画像",
        },
        {
          value: "CANDIDATE_SKILLS",
          name: "人才详情-技能标签",
        },
      ],
      displayAreasList1: [
        {
          value: "CUSTOMER_PAGING",
          name: "客户列表",
        },
        {
          value: "CUSTOMER_PROFILE",
          name: "客户详情-客户画像",
        },
        {
          value: "CUSTOMER_COOPERATION",
          name: "客户详情-合作类标签",
        },
      ],
      borderedList: [
        {
          value: true,
          name: "有边框无底色",
        },
      ],
      colorList: [
        "#56baed",
        "#6086f2",
        "#a07cf0",
        "#55c6a4",
        "#de5858",
        "#efa14d",
        "#777777",
        "#adadad",
      ],
      visible: false,
      rules: [
        {
          primaryFactor: null,
          secondaryFactor: null,
          logicOperator: "||",
          operator: "=~",
          dataValue: null,
          dataValues: [],
        },
      ],
      andFactor: [],
      andList: [],
      primaryFactorList: [],
      secondaryFactorList: [],
      andFactorList: [],
    };
  },
  created() {
    this.getLabelFactorCandidateOr(
      this.editData && this.editData.targetName == "客户"
        ? "CUSTOMER"
        : "CANDIDATE"
    );
    this.getLabelFactorCandidateAnd(
      this.editData && this.editData.targetName == "客户"
        ? "CUSTOMER"
        : "CANDIDATE"
    );
    if (this.editData) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      labelEditData(this.editData.id).then((res) => {
        if (res.success) {
          this.newLabelForm = res.result;
          if (res.result.orRules.length == 0) {
            this.rules = [
              {
                primaryFactor: null,
                secondaryFactor: null,
                logicOperator: "||",
                operator: "=~",
                dataValue: null,
                dataValues: [],
              },
            ];
          } else {
            this.rules = res.result.orRules;
          }
          this.andFactor = res.result.andRules;
          for (var i = 0; i < res.result.orRules.length; i++) {
            for (var x = 0; x < this.primaryFactorList.length; x++) {
              if (
                this.primaryFactorList[x].id ==
                res.result.orRules[i].primaryFactor
              ) {
                this.rules[i].secondaryFactorList =
                  this.primaryFactorList[x].children;
                this.rules[i].primaryFactor = Number(
                  res.result.orRules[i].primaryFactor
                );
              }
            }
          }
          if (this.copy) {
            this.newLabelForm.id = null;
            this.newLabelForm.name = null;
            this.newLabelForm.sortOrder = null;
            this.newLabelForm.createdUserId = null;
            this.newLabelForm.createdTime = null;
          }
        }
      });
    },
    //管理标签类别
    showTypes() {
      this.$emit("showTypes");
    },
    //获取颜色
    getColor(i) {
      this.newLabelForm.color = i;
      this.$refs["popover"].doClose();
    },
    //应用对象切换
    changeApplyTarget(val) {
      this.rules = [
        {
          primaryFactor: null,
          secondaryFactor: null,
          logicOperator: "||",
          operator: "=~",
          dataValue: null,
          dataValues: [],
        },
      ];
      this.getLabelFactorCandidateOr(this.newLabelForm.applyTarget);
      this.getLabelFactorCandidateAnd(this.newLabelForm.applyTarget);
    },
    //获取人才规则因子-或
    getLabelFactorCandidateOr(target) {
      getLabelFactorCandidateOr({ target: target }).then((res) => {
        if (res.success) {
          this.andList = res.result;
          this.primaryFactorList = res.result;
        }
      });
    },
    //获取人才规则因子-且
    getLabelFactorCandidateAnd(target) {
      getLabelFactorCandidateAnd({ target: target }).then((res) => {
        if (res.success) {
          this.andFactorList = res.result;
        }
      });
    },
    //检查名称是否已存在
    checkisNameExist() {
      isNameExist({
        target: this.newLabelForm.applyTarget,
        id: this.newLabelForm.id,
        name: this.newLabelForm.name,
      }).then((res) => {
        if (res.result) {
          this.$message.error("标签名称重复");
        }
      });
    },
    //检查顺序是否已存在
    checkisOrderExist() {
      //   this.newLabelForm.sortOrder=val.replace(/^\d+\.\d$/,'')
      isOrderExist({
        id: this.newLabelForm.id,
        sortOrder: this.newLabelForm.sortOrder,
      }).then((res) => {
        if (res.result) {
          this.$message.error("排序值重复");
        }
      });
    },
    number() {
      this.newLabelForm.sortOrder = this.newLabelForm.sortOrder.replace(
        /[^\.\d]/g,
        ""
      );
      this.newLabelForm.sortOrder = this.newLabelForm.sortOrder.replace(
        ".",
        ""
      );
    },
    //数据集数据处理
    getInpData(index) {
      if (
        this.rules[index].dataValue[this.rules[index].dataValue.length - 1] ==
          " " &&
        this.rules[index].dataValue.replace(/(^\s*)|(\s*$)/g, "").length > 0
      ) {
        this.rules[index].dataValues.push(
          this.rules[index].dataValue.replace(/(^\s*)|(\s*$)/g, "")
        );
        this.rules[index].dataValue = null;
      }
    },
    //删除数据集
    delItem(index, xindex) {
      this.rules[index].dataValues.splice(xindex, 1);
    },
    //创建标签
    addLabel(formName) {
      let that = this;
      return new Promise((resole) => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let RequestName = null;
            if (
              this.newLabelForm.generateModes[0] == "RULE_BASED" ||
              this.newLabelForm.generateModes[1] == "RULE_BASED"
            ) {
              for (var i = 0; i < this.rules.length; i++) {
                if (
                  that.rules[i].primaryFactor == null ||
                  that.rules[i].primaryFactor == "" ||
                  that.rules[i].secondaryFactor == null ||
                  that.rules[i].secondaryFactor == "" ||
                  that.rules[i].dataValues.length == 0
                ) {
                  if (that.rules[i].dataValue) {
                    this.rules[i].dataValues = [this.rules[i].dataValue];
                  } else {
                    this.$message.error("请输入生成逻辑");
                    return false;
                  }
                }
              }
            }
            this.newLabelForm.orRules = this.rules;
            this.newLabelForm.andRules = this.andFactor;
            if (this.editData) {
              if (this.copy) {
                this.newLabelForm.id = null;
                RequestName = addLabel(this.newLabelForm);
              } else {
                RequestName = editLabel(this.newLabelForm);
              }
            } else {
              RequestName = addLabel(this.newLabelForm);
            }
            RequestName.then((res) => {
              if (res.success) {
                this.$message.success("成功");
                this.cleanFrom("newLabelForm");
                this.$emit("list");
                this.$emit("close");
                resole();
              }
            });
          }
        });
      }).catch((error) => {});
    },
    cleanFrom() {
      this.newLabelForm = {
        name: null,
        labelType: "SKILL",
        generateModes: ["RULE_BASED"],
        categoryIds: [],
        remark: null,
        applyTarget: "CANDIDATE",
        sortOrder: null,
        displayAreas: [],
        bordered: true,
        color: "#56baed",
        orRules: [],
        andRules: [],
        version: 0,
      };
    },
    //添加规则
    addNewRules() {
      this.rules.push({
        primaryFactor: null,
        secondaryFactor: null,
        logicOperator: "||",
        operator: "=~",
        dataValue: null,
        dataValues: [],
      });
    },
    //删除规则
    delRules(index) {
      this.rules.splice(index, 1);
    },
    //添加且
    addNewAndFactor() {
      this.andFactor.push({
        logicOperator: "&&",
        andFactor: "",
      });
    },
    //删除且
    delAndFactor(index) {
      this.andFactor.splice(index, 1);
    },
    //选择一级匹配对象
    getPrimaryFactor(val, index) {
      this.rules[index].secondaryFactor = null;
      if (val) {
        for (var i = 0; i < this.primaryFactorList.length; i++) {
          if (this.primaryFactorList[i].id == val) {
            this.rules[index].secondaryFactorList =
              this.primaryFactorList[i].children;
          }
        }
      }
    },
    //一键清空数据集
    //一键复制数据集
    handleInpData(type) {
      for (var i = 0; i < this.rules.length; i++) {
        if (type == "clean") {
          this.rules[i].dataValues = [];
        } else {
          this.rules[i].dataValues = this.rules[0].dataValues;
        }
      }
    },
  },
};
</script>

<style lang='scss'>
.isColorContent {
  padding: 0 0 10px 10px;
}
.inputContent {
  .el-input__inner {
    border: 0;
  }
}
.generateSelect {
  .el-input {
    width: 180px;
  }
}
</style>

<style lang='scss' scoped>
.conbox {
  border: 1px solid #ececec;
  margin-bottom: 8px;
  h3 {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    margin: 0;
    color: #333;
    background-color: #f5f5f5;
    padding: 0 15px;
    .sub {
      font-style: 14px;
      color: #606266;
      i {
        font-style: normal;
        color: #e58a00;
      }
    }
    .fr {
      font-weight: normal;
      .addP {
        font-style: normal;
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
  .el-select {
    margin-right: 10px;
  }
}
.tips {
  color: #526ecc;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.colorBtnContent {
  .colorBtn {
    margin-top: 10px;
  }
}
.colorBtn {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: block;
  float: left;
  margin-right: 10px;
  //   margin-top: 10px;
}
.tit {
  border: 1px #ececec solid;
  border-radius: 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
  margin-top: 3px;
}
.inputContent {
  width: 500px;
  //   display: flex;
  border: 1px solid #dcdfe6;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  // height: 32px;
  margin-top: 3px;
  box-sizing: border-box;
  span {
    float: left;
    // padding-left: 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    background: #f1f1f1;
    padding: 0 10px;
    max-width: auto;
    margin: 8px 0 8px 8px;
    display: inline-table;
    box-sizing: border-box;

    cursor: pointer;
    // i {
    //   font-style: normal;
    //   font-size: 12px;
    //   color: #999;
    //    &::before {
    //       content: "x";
    //       color: #ff3f3f;
    //       position: absolute;
    //       font-family: "Flat-UI-Pro-Icons";
    //       -webkit-font-smoothing: antialiased;
    //       -moz-osx-font-smoothing: grayscale;
    //       right: 5px;
    //       top: -8px;
    //     }
    // }
    // &:hover {
    //   background-color: #ddd;
    //   color: #fff;
    //   position: relative;
    //   //   &::before {
    //   //     content: "x";
    //   //     color: #ff3f3f;
    //   //     position: absolute;
    //   //     font-family: "Flat-UI-Pro-Icons";
    //   //     -webkit-font-smoothing: antialiased;
    //   //     -moz-osx-font-smoothing: grayscale;
    //   //     right: 5px;
    //   //     top: -8px;
    //   //   }
    // }
  }
}
.isRequired:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.inputBorder {
  color: #909399;

  max-width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<template>
  <section class="box-height perfromanceTable" style="padding: 0 15px">
    <div
      class="labelLibrary"
      style="background-color: #fff; padding-bottom: 30px"
    >
      <div class="searchM">
        <el-form
          :model="filter"
          ref="filter"
          style="background-color: #fff"
          label-width="120px"
          class="clearfix"
        >
          <div class="clearfix sechContent" style="padding: 15px 0px 0">
            <el-form-item label="标签分类：" prop="types">
              <el-select
                v-model="filter.types"
                placeholder="请选择"
                clearable
                filterable
                multiple
                collapse-tags
                style="width: 240px"
              >
                <el-option
                  v-for="(item, index) in typesList"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签名称：" prop="name">
              <el-input
                style="width: 240px"
                v-model="filter.name"
                placeholder="请输入标签名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="标签类别：" prop="categoryIds">
              <el-select
                v-model="filter.categoryIds"
                placeholder="请选择"
                clearable
                filterable
                multiple
                collapse-tags
                style="width: 240px"
                popper-class="istypeSelect"
              >
                <el-option
                  v-for="(item, index) in categoryIdsList"
                  :key="'a_' + index"
                  :value="item.id"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="应用对象：" prop="applyTargets">
              <el-select
                v-model="filter.applyTargets"
                placeholder="请选择"
                clearable
                filterable
                multiple
                collapse-tags
                style="width: 240px"
              >
                <el-option
                  v-for="(item, index) in applyTargetsList"
                  :key="'a_' + index"
                  :value="item.value"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0">
              <el-button
                style="margin-left: 15px"
                type="primary"
                size="mini"
                @click="getData"
                >搜索</el-button
              >
            </el-form-item>
          </div>
          <el-col :span="24">
            <div style="float: left">
              <el-button
                style="margin-left: 15px"
                size="mini"
                @click="getNew('')"
                >新增标签</el-button
              >
              <el-button
                style="margin-left: 10px"
                size="mini"
                @click="showTypes"
                >管理标签类别</el-button
              >
            </div>
            <el-pagination
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination>
          </el-col>
        </el-form>
      </div>
      <div
        class="pd40"
        style="background-color: #fff; padding-top: 7px; padding-bottom: 20px"
      >
        <el-table
          :data="ListData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column
            prop="labelTypeName"
            min-width="150"
            label="标签分类"
          >
          </el-table-column>
          <el-table-column prop="name" min-width="150" label="标签名称">
          </el-table-column>
          <el-table-column
            prop="categoryName"
            min-width="150"
            label="标签类别"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="targetName" min-width="150" label="应用对象">
          </el-table-column>
          <el-table-column prop="sortOrder" min-width="150" label="排序">
          </el-table-column>
          <el-table-column prop="creatorName" min-width="150" label="创建人">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间" min-width="150">
          </el-table-column>
          <el-table-column prop="hitCount" min-width="150" label="命中数量">
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span class="tabHref" size="mini" @click="getNew(scope.row)"
                >编辑
              </span>
              <el-divider direction="vertical"></el-divider>
              <span
                class="tabHref"
                size="mini"
                @click="getNew(scope.row, 'copy')"
                >复制</span
              >
              <el-divider direction="vertical"></el-divider>
              <span class="tabHref" size="mini" @click="handleSet(scope.row.id)"
                >手动执行</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </div>
    <el-drawer
      :visible.sync="labelDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleLabelClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ titleName }}</div>
      <div class="drawerCon">
        <addnewLabel
          ref="addnewLabel"
          :categoryIdsList="categoryIdsList"
          :editData="editData"
          :copy="copy"
          @showTypes="showTypes"
          @list="loadInfolist"
          @close="handleLabelClose"
        ></addnewLabel>
        <div class="drawerCon_footer fixedFoot">
          <el-button type="default" plain size="mini" @click="handleLabelClose"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="saveInfos"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 管理标签类别 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">管理标签类别</div>
      <div class="drawerCon" style="padding: 0">
        <div>
          <el-button style="margin: 15px" size="mini" @click="shownewDig"
            >新增</el-button
          >
          <el-pagination
            :hide-on-single-page="page.total > 10 ? false : true"
            v-if="page.total > 0"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="page.current"
            :page-size="page.size"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="page.total"
            :page-sizes="[10, 20, 50, 100]"
            style="margin: 15px"
          ></el-pagination>
        </div>
        <el-table
          :data="typeListData"
          v-loading="listLoading1"
          :header-cell-style="{
            'background-color': '#f5f7fa',
            'border-top': '1px #e5e5e5 solid',
          }"
          class="tabBorder custor"
          style="width: 100%; margin: 0px; border-top: 0"
        >
          <el-table-column prop="name" min-width="150" label="类别名称">
          </el-table-column>
          <el-table-column prop="labelType" min-width="150" label="标签分类">
            <template slot-scope="scope">
              <span>{{
                scope.row.labelType == "SKILL" ? "技能标签" : "履历标签"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="sortOrder" min-width="150" label="排序">
          </el-table-column>
          <el-table-column prop="createUserName" min-width="150" label="创建人">
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间" min-width="150">
          </el-table-column>
          <el-table-column
            prop="candidateCount"
            min-width="150"
            label="该类别下的标签数量"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.labelNums ? scope.row.labelNums : 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="left" width="180">
            <template slot-scope="scope">
              <span size="mini">- </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="page.total > 10 ? false : true"
          v-if="page.total > 0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="page.current"
          :page-size="page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="page.total"
          :page-sizes="[10, 20, 50, 100]"
          style="padding: 10px 15px 20px"
        ></el-pagination>
      </div>
    </el-drawer>
    <!-- 新增管理标签类别 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      class="matchDialog"
      title="新增"
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose1"
      destroy-on-close
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="createDateRules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="标签类别名称：" prop="name">
              <el-input
                v-model="createDate.name"
                placeholder=" 请输入标签类别"
                :maxlength="20"
                @blur="checkisNameExist"
                :onkeyup="
                  (createDate.name = createDate.name.replace(/\s+/g, ''))
                "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标签分类：" prop="labelType">
              <el-radio v-model="createDate.labelType" label="SKILL"
                >技能标签</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="排序：" prop="sortOrder">
              <el-input
                v-model="createDate.sortOrder"
                placeholder="请输入"
                @blur="checkisOrderExist"
                @keyup.native="number"
              ></el-input>
              <p style="color: #999; font-size: 14px">
                前端页面将按照排序值从小到大排序
              </p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="footer-title clearfix">
        <span>
          <el-button type="default" size="mini" @click="handleClose1"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="save('createDate')"
            >保存</el-button
          >
        </span>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  labelPage, //标签分页查询
  labelCategory, //新增标签类别
  labelCategoryGetPageList, //分页获取标签类别
  getLabelCategory, //获取所有标签类别
  categoryIsNameExist, //检查名称是否已存在
  categoryIsOrderExist, //检查顺序是否已存在
  setLabelProcess, //处理当前标签
} from "../../api/api";
import addnewLabel from "../../components/set/addnewLabel.vue";
export default {
  components: {
    addnewLabel,
  },
  data() {
    return {
      filter: {
        applyTargets: [],
        categoryIds: [],
        name: "",
        types: "",
        page: {
          total: 0,
          current: 1,
          size: 50,
        },
      },
      typesList: [
        {
          value: "SKILL",
          name: "人才技能标签",
        },
        {
          value: "RESUME",
          name: "人才履历标签",
        },
        {
          value: "CUSTOMER_INFLUENCE",
          name: "客户影响力标签",
        },
        {
          value: "CUSTOMER_COOPERATION",
          name: "客户合作类标签",
        },
      ],
      categoryIdsList: [],
      applyTargetsList: [
        {
          value: "CANDIDATE",
          name: "人才",
        },
        {
          value: "CUSTOMER",
          name: "客户",
        },
      ],
      ListData: [],
      drawerVisible: false,
      page: {
        total: 0,
        current: 1,
        size: 50,
      },
      typeListData: [],
      dialogVisible: false,
      createDate: {
        name: "",
        labelType: "SKILL",
        sortOrder: "",
      },
      createDateRules: {
        name: [
          { required: true, trigger: "blur", message: "标签类别名称不能为空" },
        ],
        labelType: [
          { required: true, trigger: "change", message: "标签分类不能为空" },
        ],
        sortOrder: [
          { required: true, trigger: "blur", message: "排序不能为空" },
        ],
      },
      saveloading: false,
      listLoading1: false,
      labelDrawerVisible: false,
      titleName: "",
      editData: null,
      copy: false,
      source: null,
    };
  },
  created() {
    this.loadInfolist();
    this.getLabelCategory();
  },
  methods: {
    //列表搜索
    getData() {
      this.filter.page.current = 1;
      this.filter.page.size = 50;
      this.loadInfolist();
    },
    //关闭标签抽屉
    handleLabelClose() {
      this.editData = null;
      this.copy = false;
      this.labelDrawerVisible = false;
    },
    //新增编辑复制标签
    getNew(val, type) {
      this.getLabelCategory();
      if (type == "copy") {
        this.copy = true;
      }
      val.id ? (this.editData = val) : (this.editData = null);
      val.id
        ? type == "copy"
          ? (this.titleName = "新增标签")
          : (this.titleName = "编辑标签")
        : (this.titleName = "新增标签");
      this.labelDrawerVisible = true;
      this.getLabelCategory();
    },
    //
    async handleSet(id) {
      let that = this;
      this.$message.success("已执行，后台处理中");
      await setLabelProcess({ id: id }, this).then((res) => {
        // this.$message.success('已执行，后台处理中')
      });
      that.cancelRequest();
    },
    cancelRequest() {
      if (typeof this.source === "function") {
        this.source("终止请求"); //取消请求
      }
    },
    //保存标签
    saveInfos() {
      this.$refs.addnewLabel.addLabel("newLabelForm").then((value) => {
        this.labelDrawerVisible = false;
        this.loadInfolist();
      });
    },
    number() {
      this.createDate.sortOrder = this.createDate.sortOrder.replace(
        /[^\.\d]/g,
        ""
      );
      this.createDate.sortOrder = this.createDate.sortOrder.replace(".", "");
    },
    //复制
    // copy() {

    // },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.loadInfolist();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.filter.page.current = val;
      this.loadInfolist();
    },
    //获取全部标签类别
    getLabelCategory() {
      getLabelCategory().then((res) => {
        if (res.success) {
          this.categoryIdsList = res.result;
        }
      });
    },
    //获取列表数据
    loadInfolist() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      labelPage(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.filter.page.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    //管理标签类别
    showTypes() {
      this.drawerVisible = true;
      this.loadTypelist();
    },
    //pageSize 改变时会触发
    handleSizeChange1(val) {
      this.page.size = val;
      this.page.current = 1;
      this.loadTypelist();
    },
    //currentPage 改变时会触发
    handleCurrentChange1(val) {
      this.page.current = val;
      this.loadTypelist();
    },
    //获取管理标签类别列表
    loadTypelist() {
      $(".el-drawer__body").scrollTop(0);
      this.listLoading1 = true;
      labelCategoryGetPageList(this.page).then((res) => {
        if (res.success) {
          this.listLoading1 = false;
          this.typeListData = res.result.records;
          this.page.total = res.result.total;
        }
        this.listLoading1 = false;
      });
    },
    //新增标签类别
    shownewDig() {
      this.dialogVisible = true;
    },
    //关闭管理标签类别
    handleClose() {
      this.drawerVisible = false;
      this.getLabelCategory();
    },
    //关闭新增管理标签类别
    handleClose1() {
      this.dialogVisible = false;
      this.createDate = {
        name: "",
        labelType: "SKILL",
        sortOrder: "",
      };
    },
    //检查名称是否已存在
    checkisNameExist() {
      categoryIsNameExist({
        name: this.createDate.name,
      }).then((res) => {
        if (res.result) {
          this.$message.error("标签类别名称重复");
        }
      });
    },
    //检查顺序是否已存在
    checkisOrderExist() {
      categoryIsOrderExist({
        sortOrder: this.createDate.sortOrder,
      }).then((res) => {
        if (res.result) {
          this.$message.error("排序值重复");
        }
      });
    },
    //保存标签类别
    save(formName) {
      this.saveloading = true;
      labelCategory(this.createDate).then((res) => {
        if (res.success) {
          this.$message.success("保存成功");
          this.createDate = {
            name: "",
            labelType: "SKILL",
            sortOrder: "",
          };
          this.dialogVisible = false;
          this.loadTypelist();
        }
        this.saveloading = false;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.searchM {
  margin-bottom: 5px;
  .el-form-item {
    float: left;
    margin-bottom: 15px;
  }
}
.istypeSelect {
  .el-select-dropdown__item {
    padding-right: 35px;
  }
}
</style>